import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "./travelspiritwebsite.scss";
import kofferLabel from '../assets/images/labels/website-label.png';
import addonVideo from '../assets/videos/mediaspirit-academy.mp4';
import { Link } from "gatsby";

export default () => {
  return (
    <Layout>
      <SEO title="Travelspirit Website (SPoE)" urlParams="travelspiritwebsite" />
      <div>
        <div className="website-travel-page-full website-back-office-section-first">
          <div className="website-travel-page-container">
            <div className="website-back-office-content">
              <h6>Single Point of Entry</h6>
              <div className="website-back-office-wrap">
                <h1>WEBSITE<br />(SPOE)</h1>
                <img alt="backoffice-koffer label" src={kofferLabel} />
              </div>
              <p>Met de Website (Single Point of Entry) oplossing van TravelSpirit <strong>beheer je alle product- en pakketinformatie op één centrale plek.</strong> Dit zorgt ervoor dat er geen verschillen meer zijn tussen het systeem en de website, zodat je altijd beschikt over de meest actuele en consistente informatie.</p>
              <ul className="website-travel-page-container-ul">
                <li>Single Point of Entry</li>
                <li>Fotobeheer (MediaSpirit)</li>
                <li className="website-fullwidth">Producten & pakketten</li>
                <li className="website-fullwidth">Online prijzen</li>
                <li className="website-fullwidth">Boekbare website</li>
                <li className="website-fullwidth">Online boeken</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="website-travel-page-full website-back-office-section-two" id="single-point-of-entry">
          <div className="website-travel-page-container">
            <div className="website-all-travel-common-box-main">
              <div className="website-all-travel-common-box-left"></div>
              <div className="website-all-travel-common-box-right">
                <h3>Single Point of Entry</h3>
                <p>Met de 'Single Point of Entry' is het <strong>niet meer nodig</strong> om gegevens over elementen, bouwstenen of voorbeeldreizen, op <strong>meerdere plekken bij te houden</strong>, zoals in het backoffice systeem en op de website. De omschrijving van het hotel gaat bv. naar de website en de uitgebreidere omschrijving komt op de offerte.</p>
                <p>TravelSpirit levert <strong>'Software Development Kits'</strong> en <strong>koppelingen</strong> met <strong>CMS systemen</strong>, zoals WordPress. Zo kun je gebruik maken van de eigen webbouwer, maar ook met één van onze preferred partners een samenwerkingsverband aangaan.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="website-travel-page-full website-crm-office-section" id="products-package-content">
          <div className="website-travel-page-container">
            <div className="website-all-travel-common-box-main">
              <div className="website-all-travel-common-box-left">
                <h3>“Altijd toegang hebben tot consistente informatie, zowel op je website als bijv. in de offertes.”</h3>
              </div>
              <div className="website-all-travel-common-box-right">
                <h3>Producten & pakketten</h3>
                <p>Voeg eenvoudig <strong>reisproducten</strong>, zoals pakketten en hotels, inclusief teksten en afbeeldingen, toe in TravelSpirit. Deze producten worden <strong>automatisch doorgeschoten naar je</strong> website, waardoor je je online aanbod <strong>altijd actueel</strong> houdt. Bovendien kun je dezelfde informatie gebruiken in je offertes, wat het proces nog efficiënter maakt. </p>
                <p>Dankzij deze integratie bespaar je niet alleen tijd, maar zorg je er ook voor dat je klanten <strong>altijd toegang</strong> hebben tot <strong>consistente informatie</strong>, zowel op je website als bijv. in de offertes.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="website-travel-page-full website-telephonie-section">
          <div className="website-travel-page-container">
            <div className="website-all-travel-common-box-main">
              <div className="website-all-travel-common-box-left">
                <div></div>
              </div>
              <div className="website-all-travel-common-box-right">
                <h3>Online prijzen</h3>
                <p>Bij een pakketreis worden al deze prijzen voor het hele jaar berekend, zodat op de website een kalender met een prijs per dag getoond kan worden, <strong>zonder vervelende waitingspinners</strong></p>
                <p>In de BackOffice kun je de <strong>prijzen</strong> tot op <strong>element - en periodeniveau</strong> invoeren, inclusief de "gekste" specials die de leveranciers kunnen verzinnen. Denk aan 7=6, schoonmaakkosten, toeristenbelasting, early bird, weekendtoeslagen, etc.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="website-travel-page-full website-email-section">
          <div className="website-travel-page-container">
            <div className="website-all-travel-common-box-main">
              <div className="website-all-travel-common-box-left">
                <h3>“Een boeking wordt automatisch in het BackOffice systeem verwerkt.”</h3>
              </div>
              <div className="website-all-travel-common-box-right">
                <h3>Boekbare website</h3>
                <p>Wil je <strong>direct boekingen</strong> kunnen aannemen op je website? Dat kan! Een boeking wordt <strong>automatisch</strong> in het BackOffice <strong>systeem verwerkt</strong>, wanneer deze op de site geplaatst is, zodat daar de verdere afhandeling kan plaatsvinden. Denk hierbij aan het bestellen bij de leveranciers, de financiële afhandeling en het doorsturen ervan naar de boekhouding. </p>
              </div>
            </div>
          </div>
        </div>
        <div className="website-travel-page-full website-workflow-section" id="online-booking">
          <div className="website-travel-page-container">
            <div className="website-all-travel-common-box-main">
              <div className="website-all-travel-common-box-left">
                <h3>“Met de 'Single Point of Entry' van TravelSpirit beheer je alle gegevens zoals hotelomschrijvingen en prijzen op één plek.”</h3>
              </div>
              <div className="website-all-travel-common-box-right">
                <h3>Online boeken</h3>
                <p>Met de 'Single Point of Entry' van TravelSpirit beheer je alle gegevens zoals hotelomschrijvingen en prijzen op één plek, zonder duplicatie tussen backoffice en website. <strong>Via koppelingen met CMS-systemen </strong> zoals WordPress werk je samen met je eigen webbouwer of onze partners. </p>
                <p>Prijzen kunnen tot op element- en periodeniveau worden ingevoerd, inclusief specials zoals 7=6 en schoonmaakkosten. Voor pakketreizen worden prijzen voor het hele jaar berekend, zodat je een kalender met dagprijzen kunt tonen zonder vertraging.</p>
                <p><strong>Boekingen</strong> worden <strong>automatisch</strong> in het BackOffice <strong>verwerkt</strong> voor de verdere afhandeling, zoals bestellingen en financiële verwerking. Met MediaSpirit wordt je website sneller en beelden mooier.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="website-travel-page-full website-producten-section" id="photo-management-mediaspirit">
          <div className="website-travel-page-container">
            <div className="website-all-travel-common-box-main">
              <div className="website-all-travel-common-box-left">
                <div className="video-wrapper">
                    <video className="responsive-video" autoPlay muted loop width="400" height="500">
                        <source src={addonVideo} />
                    </video>
                </div>
              </div>
              <div className="website-all-travel-common-box-right">
                <h3>Fotobeheer (MediaSpirit)</h3>
                <p>Beelden zeggen meer dan duizend woorden! Verfraai de reisoffertes met mooie foto's. Deze zijn <strong>altijd op maat</strong>, wanneer je gebruikmaakt van MediaSpirit, of ze nu bestemd zijn voor de offerte als website, of voor de PDF.</p>
                <p>De foto's kun je terugvinden met de <strong>tags</strong> die je zelf aan de foto's hebt toegekend, alsmede met <strong>cognitieve tags</strong>, zoals 'sunset' of 'temple', die door het MediaSpirit systeem zijn toegevoegd</p>
              </div>
            </div>
          </div>
        </div>

        <div className="website-travel-page-full website-kost-section">
          <div className="website-travel-page-container">
            <div className="website-kost-section-content">
              <h2>Wat kost de Website (SPoE)?</h2>
              <p>Voor elke type reisorganisatie bieden wij een passend abonnement inclusief de Website (SPoE).</p>
              <Link key='website-pricing' to={`/pricing`}>
                Bekijk abonnementen <span>&gt;</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="website-travel-page-full website-benieuwd-section">
          <div className="website-travel-page-container">
            <div className="website-benieuwd-section-content">
                <h2>Benieuwd wat TravelSpirit voor jou kan betekenen?</h2>
                <p>Wij gaan graag met je in gesprek om de mogelijkheden te bespreken om je verder te helpen.</p>
                <div className="website-benieuwd-btn">
                    <div className="benieuwd-btn-item">
                        <Link key='website-demo' to={`/demo`} className="btn btn-primary demo-implement">
                            Demo inplannen
                        </Link>
                        <span className="benieuwd-btn-item-text">Online sessie van 30 minuten</span>
                    </div>
                    <div className="benieuwd-btn-item">
                        <Link key='website-verkennend' to={`/demo-afspraak`} className="btn btn-primary verkennend-implement">
                            Verkennend gesprek
                        </Link>
                        <span className="benieuwd-btn-item-text">Stel online al jouw vragen</span>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
